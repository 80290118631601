import React, {Fragment, useEffect} from "react"
import {Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { LANGUAGES } from "../constants";
import { Translation } from 'react-i18next';
import i18n from '../i18n';

function withHooks(WrappedComponent) {
    return function(props) {
        const nav = useNavigate();
        return <WrappedComponent nav={nav} {...props}/>
    };
}

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'lang': i18n.language,
            'name': JSON.parse(localStorage.getItem('spr_name'))
        };
        //const { t, i18n } = Translation("common");
        //console.log(i18n.language);
    }

    handleClick = () => {
        const navigate = this.props.nav;
        localStorage.setItem('spr_name', JSON.stringify(this.state.name));
        navigate("/word", {state:{lang: i18n.language, name: this.state.name}})
    };

    handleNickChange = (e) => {
        this.setState({name: e.target.value});
    };

    handleLangChange = (e) => {
        this.setState({lang: e.target.value});
        i18n.language = e.target.value;
       // console.log(i18n.language);
    };

    render() {
        return (
            <header className="App-header d-grid gap-2">
                <Fragment>
                    <input
                        id="nick-input"
                        type="text"
                        placeholder="Введите Ник"
                        value={this.state.name}
                        onInput={this.handleNickChange}/>

                    <select id="lang" value={this.state.lang} onInput={this.handleLangChange}>
                        {LANGUAGES.map(({ code, label }) => (
                            <option key={code} value={code}>
                                {label}
                            </option>
                        ))}
                    </select>
                </Fragment>
                <p>
                    <Button className="weiter" variant="secondary" size="lg" onClick={this.handleClick} >
                        <Translation>
                            {
                                t => <h1>{t('login')}</h1>
                            }
                        </Translation>
                    </Button>
                </p>
            </header>
        );
    }
}
export default withHooks(Login)
