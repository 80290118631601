import React from 'react'
import {Button} from "react-bootstrap";
import Autocomplete from '../Autocomplete';
import User from "../User";
import ReactHtmlParser from 'react-html-parser';
import {useLocation, useNavigate} from 'react-router-dom';
import elementTypes from "react-html-parser/lib/elementTypes";
import {Translation} from "react-i18next";
import i18n from "../i18n";

function withHooks(WrappedComponent) {
    return function(props) {
        const location = useLocation();
        const navigate = useNavigate();
        return <WrappedComponent location={location} navigate={navigate} {...props}/>
    };
}

class Word extends React.Component {
    constructor(props) {
        super(props);

        this.state = { kart:
                {
                    id: 0,
                    word: '',
                    translate: '',
                    example: '',
                    example_translate: ''
                },
            answers: '',
            input: JSON.parse(localStorage.getItem('input_ball')),
            open: 0,
            inputOpen: 1,
            vorbei: [],
            repeat_value: 0,
            user: JSON.parse(localStorage.getItem('spr_name')) || props.location.state.name,
            lang: props.location.state.lang
        };

        i18n.changeLanguage(props.location.state.lang);

    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    getData = (val) =>{
        if (val === this.state.kart.word) {
            this.openTranslate()
            this.setState(state =>({input: this.state.input+1, inputOpen: 0}))
            localStorage.setItem('input_ball', JSON.stringify(this.state.input+1));
            this.sleep(5000).then(r => {
                this.openInput()
                this.nextWord()
                this.addVorbyId(this.state.kart.id)
            })
        }
    }

    addVorbyId = (id) => {
        this.setState(state => ({
            vorbei: [...state.vorbei, id]
        }))
    }


    nextWord = () => {
        this.getWord()
    };

    newWord = () => {
        this.getWord(this.state.kart.id+1)
    };

    logOut = () => {
        this.props.navigate('/');
    }

    openTranslate = () => {
        this.setState(state => ({
            open: 1
        }))
    }

    openInput = () => {
        this.setState({ inputOpen: 1 })
    }

    getWord = () => {
        //console.log(JSON.parse(localStorage.getItem('spr_name')))
        /*
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch('http://0.0.0.0:3001/words/'+ id, requestOptions)
         - da kan man drin-server anschliesen*/
        //fetch('http://88.85.67.159:8094/word/' + id)
        fetch('http://88.85.67.159:8094/rand-word/'+this.state.lang)
            .then(response => response.json())
            .then(data => {
                if (this.state.repeat_value>30){
                    window.location.reload();
                }
                else if (data.status === false ||
                    this.state.vorbei.includes(data.id) ) {
                    console.log(data.id)
                    this.setState(state => ({repeat_value: this.state.repeat_value+1}))
                    this.getWord()
                }
                else {
                    console.log(data.definitions[0])
                    //var item = items[Math.floor(Math.random()*items.length)];
                    let ind = Math.floor(Math.random()*data.definitions.length)
                    this.setState(state => ({
                        kart:
                            {
                                id: data.id,
                                word: data.word,
                                translate: data.definitions[ind].value,
                                example: this.getHtmlFromExampleObjArr(data.definitions[ind].examples),
                            },
                        open: 0,
                        repeat_value: this.state.repeat_value+1
                    }))

                }
            })
    }

    getAnswers(json){
        let arr = []
        for(let i = 0; i < json.length; i++) {
            let obj = json[i];
            arr.push(obj);
        }
        this.state.answers = arr;
    }

    getHtmlFromExampleObjArr(arr){
        let string = ``
        for (let i = 0; i < arr.length; i++) {
            let ex = arr[i].text;
            string += ex + "<br>";
        }
        console.log(string)
        return string
    }

    parseHTML(html) {
        let t = document.createElement('template');
        t.innerHTML = html;
        return t.content;
    }

    componentDidMount() {

        //fetch('http://0.0.0.0:3001/words', { method: 'GET',  headers: { 'Content-Type': 'application/json' }})
        fetch('http://88.85.67.159:8094/words')
            .then(response => response.json())
            .then(data => this.getAnswers(data));
        this.getWord()
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <header className="App-header d-grid gap-2">
                <User name={this.state.user} lang={this.state.lang} />
                <div className="Word">
                    <p className="definition">{ReactHtmlParser(this.state.kart.translate)}</p>
                    <p className={this.state.open ? undefined :'hidden'}>{this.state.kart.word}</p>
                    <p className={this.state.open ? undefined :'hidden'}>{ReactHtmlParser(this.state.kart.example)}</p>
                    <p className="ball">{this.state.input}</p>
                </div>
                <Autocomplete suggestions={this.state.answers} sendData={this.getData} inputOpen={this.state.inputOpen}/>
                <p className={this.state.open ? 'hidden': undefined }>

                    <Translation i18n={i18n}>
                        {
                            (t, { i18n }) =>
                                <Button className="weiter" variant="secondary" size="lg" onClick={this.newWord} >
                                    {t('next')}
                                </Button>
                        }
                    </Translation>
                </p>
                <p className={this.state.open ? 'hidden': undefined }>

                    <Translation i18n={i18n}>
                        {
                            (t, { i18n }) =>
                                <Button className="weiter" variant="secondary" size="lg" onClick={this.logOut} >
                                    {t('logout')}
                                </Button>
                        }
                    </Translation>

                </p>
            </header>
        );
    }
}

export default withHooks(Word)